<template>
  <p
    v-if="isEligibleToDisplay"
    class="font-rollerSmooth absolute z-10 text-[1.5rem] md:text-[2rem] text-center leading-none"
    :class="[
      positionClassNameResolver,
      onCardHoverClassNamesResolver,
      maxWidthClassNameResolver,
    ]"
  >
    {{ handWrittenLabelData.label }}
  </p>
</template>

<script setup lang="ts">
import type { HandWrittenLabelData } from "@/types";
import { productCardSizes } from "@/constants/productCard";
import { cmsPageTypes } from "@/constants/cmsPageTypes";
const { isHomePage, pageType } = toRefs(useActivePage());
const props = defineProps<{
  handWrittenLabelData: HandWrittenLabelData;
}>();

const isEligibleToDisplay = computed(() => {
  if (isHomePage.value) return true;
  return pageType.value === cmsPageTypes["PRODUCT_LISTING"];
});

const handWrittenLabelPositions = {
  TOP_LEFT: "top_left",
  TOP_RIGHT: "top_right",
  BOTTOM_LEFT: "bottom_left",
  BOTTOM_RIGHT: "bottom_right",
} as const;

const positionClassNameResolver = computed(() => {
  switch (props.handWrittenLabelData?.position) {
    case handWrittenLabelPositions["TOP_LEFT"]:
      return "top-10 md:top-12 left-2 md:left-4 rotate-[-10deg]";
    case handWrittenLabelPositions["TOP_RIGHT"]:
      return "top-10 md:top-12 right-2 md:right-4 rotate-[10deg]";
    case handWrittenLabelPositions["BOTTOM_LEFT"]:
      return "bottom-10 md:bototm-12 left-2 md:left-4 rotate-[-10deg]";
    case handWrittenLabelPositions["BOTTOM_RIGHT"]:
      return "bottom-10 md:bototm-12 right-2 md:right-4 rotate-[10deg]";
    default:
      return "top-10 md:top-12 left-2 md:left-4 rotate-[-10deg]";
  }
});

const maxWidthClassNameResolver = computed(() => {
  if (
    props.handWrittenLabelData.size === productCardSizes["DOUBLE"] ||
    props.handWrittenLabelData.size === productCardSizes["LARGE"]
  ) {
    return "max-w-[135px] md:max-w-[180px]";
  }
});

const onCardHoverClassNamesResolver = computed(() => {
  if (!props.handWrittenLabelData.hideOnCardHover) return;
  return "group-hover:opacity-0 transition-opacity";
});
</script>
